import * as React from 'react';
import { Link } from 'gatsby';
import { Box, Container, Stack, Flex, SimpleGrid } from '@chakra-ui/react';
import { StaticImage } from 'gatsby-plugin-image';
import ReactPlayer from 'react-player';
// import Carousel from 'react-multi-carousel';
import SEO from '../../components/seo';
import Layout from '../../components/layout';
import CustomButton from '../../components/custom-button';
import '../../styles/applications/agriculture-yields.scss';
import PlayIcon from '../../images/play-icon.svg';

const Benefits = () => {
  // const responsive = {
  //   desktop: {
  //     breakpoint: { max: 3000, min: 1024 },
  //     items: 1,
  //     slidesToSlide: 1, // optional, default to 1.
  //   },
  //   tablet: {
  //     breakpoint: { max: 1024, min: 464 },
  //     items: 1,
  //     slidesToSlide: 1, // optional, default to 1.
  //   },
  //   mobile: {
  //     breakpoint: { max: 464, min: 0 },
  //     items: 1,
  //     slidesToSlide: 1, // optional, default to 1.
  //   },
  // };
  const config = {
    config: {
      file: {
        attributes: {
          controlsList: 'nodownload',
        },
      },
    },
    playIcon: <img src={PlayIcon} alt="playicon" />,
    url: 'https://www.youtube.com/watch?v=jD_Kid4EUAA',
    light:
      'https://pacemediafiles.s3.us-west-2.amazonaws.com/ostara/ostara-testimonial-min.jpeg',
    playing: true,
    controls: true,
    width: '100%',
  };
  return (
    <Layout>
      <SEO
        title="Improve Yield | Ostara"
        description="Improve yield with a nutrient-rich fertilizer formula and patented Root-Activated technology that is backed by over 14 years of research."
        image="https://pacemediafiles.s3.us-west-2.amazonaws.com/ostara/ostara_logo.png"
        keywords="Improve Yield"
      />
      <main>
        <Box w="100%" p={4} className="product-hero-container">
          <Container maxW={1400}>
            <Box
              display="flex"
              flexDirection={['column', 'column', 'column', 'row']}
              justifyContent="space-between"
              paddingTop={[0, 0, 10, 50]}
            >
              <Box
                marginTop={[12, 12, 12, 100]}
                w={['100%', '100%', '100%', '46.5%']}
              >
                <Stack maxW="50rem" minH="250px" spacing={6}>
                  <h1>Improve Yield</h1>
                  <p>
                    Crops benefit from the right amount of phosphate at the
                    right time, and so does your ROI.
                  </p>
                  <div className="button-wrappers">
                    <CustomButton to="/contact">CONTACT US</CustomButton>
                  </div>
                </Stack>
              </Box>
              <Stack
                spacing={[4, 6, 4, 6, 8]}
                w={['100%', '100%', '100%', '46.5%']}
              >
                <Box className="benefits-hero-img-container">
                  <StaticImage
                    className="hero-img"
                    placeholder="transparent"
                    src="../../images/improve-yield-min.png"
                    alt="Ostara hero img"
                  />
                </Box>
              </Stack>
            </Box>
          </Container>
        </Box>

        <Box w="100%" p={4} className="benefits-body-container">
          <Flex direction="column">
            <Container
              className="benefits-body-content benefits-body-first-part"
              maxW={1400}
            >
              <Container maxW="container.md">
                <h2>
                  Crystal Green<sup>®</sup> Fertilizers Are Designed to Improve
                  Yield
                </h2>
                <p>
                  Crystal Green is proven to enhance yield by ongoing research
                  with accredited institutions from around the world.
                </p>
              </Container>
              <Container className="benefits-body-icons" maxW="container.md">
                <div className="benefits-icon">
                  <StaticImage
                    placeholder="transparent"
                    src="../../images/optimized-nutrient-formula.png"
                    alt="Ostara icons"
                  />
                  <h5>Optimized Nutrient Formula</h5>
                </div>
                <div className="benefits-icon">
                  <StaticImage
                    placeholder="transparent"
                    src="../../images/benefits-icon-2.png"
                    alt="Ostara icons"
                  />
                  <h5>
                    Crop Driven
                    <br />
                    Release
                  </h5>
                </div>
                <div className="benefits-icon">
                  <StaticImage
                    placeholder="transparent"
                    src="../../images/improve-yied-03-thumb.png"
                    alt="Ostara icons"
                  />
                  <h5>Continuous Availability, Continuous Nutrition</h5>
                </div>
                <div className="benefits-icon">
                  <StaticImage
                    placeholder="transparent"
                    src="../../images/benefits-icon-4.png"
                    alt="Ostara icons"
                  />
                  <h5>One Application All Season Long</h5>
                </div>
              </Container>
            </Container>
          </Flex>
          <Container maxW={1400}>
            <hr className="custom-divider" />
          </Container>
          <Container className="benefits-body-content-container" maxW={1400}>
            <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={6}>
              <div>
                <StaticImage
                  placeholder="transparent"
                  src="../../images/diagram-elements.png"
                  alt="Ostara chart"
                  height={300}
                />
              </div>
              <Flex className="benefits-body-content">
                <h1>01</h1>
                <h3>Optimized Nutrient Formula</h3>
                <p>
                  Crystal Green delivers phosphate, nitrogen and magnesium
                  (5-28-0 with 10% Mg) more efficiently than conventional
                  phosphate fertilizers to provide crops the nutrients they need
                  all season.
                </p>
                <Link to="/product/crystal-green">Explore Fertilizer</Link>
              </Flex>
            </SimpleGrid>
          </Container>
          <Container maxW={1400}>
            <hr className="custom-divider" />
          </Container>
          <Container className="benefits-body-content" maxW={1400}>
            <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={6}>
              <Flex className="benefits-body-content-2">
                <h1>02</h1>
                <h3>Crop Driven Release</h3>
                <p>
                  Find out what enhanced efficiency means for your crops.
                  Crystal Green releases nutrients only in response to organic
                  acids produced by growing roots, increasing fertilizer
                  efficiency and minimizing nutrient loss.
                </p>
                <Link to="/product/crystal-green">Explore Fertilizer</Link>
              </Flex>
              <StaticImage
                placeholder="transparent"
                src="../../images/improve-yield-icon2.png"
                alt="Ostara icons"
              />
            </SimpleGrid>
          </Container>
          <Container maxW={1400}>
            <hr className="custom-divider" />
          </Container>
          <Container className="benefits-body-content-3-yield" maxW={1400}>
            <Flex className="benefits-body-content-3">
              <h1>03</h1>
              <h3>Available Nutrition On-Demand</h3>
              <p>
                Safely provide soil with an ongoing supply of phosphate for
                optimal plant growth.
                <br />
                With a crop-driven mode of action, Crystal Green fertilizers
                only release nutrients according to plant demand.
              </p>
              <Link to="/product/crystal-green">Explore Fertilizer</Link>
            </Flex>
          </Container>
          <Container maxW={1400}>
            <hr className="custom-divider" />
          </Container>
          <Container className="benefits-body-content" maxW={1400}>
            <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={6}>
              <Flex className="benefits-body-content-2">
                <h1>04</h1>
                <h3>One Application All Season Long</h3>
                <p>
                  Feed crops with a phosphate fertilizer that only requires one
                  season-long application. Crystal Green fertilizers are
                  sparingly water soluble and stay in the soil to continuously
                  release phosphate, nitrogen, and magnesium all season to
                  enhance fertilizer efficiency and improve ROI.
                </p>
                <Link to="/product/crystal-green">Explore Fertilizer</Link>
              </Flex>
              <StaticImage
                placeholder="transparent"
                src="../../images/Graphic-green-p-season-availabilty.png"
                alt="Ostara icons"
              />
            </SimpleGrid>
          </Container>
        </Box>

        {/* <Box w="100%" p={4} className="benefits-data-container">
          <Container maxW={1400}>
            <h4>THE DATA</h4>
            <h2>Data-Driven Results Show Enhanced Yield</h2>
            <SimpleGrid
              columns={{ md: 1, lg: 2 }}
              className="benefits-data-info"
              spacing={6}
            >
              <Box className="benefits-data-content">
                <h3>CRYSTAL GREEN VS MAP</h3>
                <h5>Increased Stand Count</h5>
                <h5>Soil pH and Bushel Increase</h5>
                <CustomButton to="/contact" maxW={250}>
                  MORE DATA & INSIGHTS
                </CustomButton>
              </Box>
              <Box className="benefits-data-chart">
                <Carousel
                  swipeable={false}
                  draggable={false}
                  showDots
                  responsive={responsive}
                  arrows={false}
                  infinite
                  autoPlay
                  ssr
                  autoPlaySpeed={4000}
                  transitionDuration={500}
                  containerClass="benefits-carousel-container"
                  removeArrowOnDeviceType={['tablet', 'mobile']}
                >
                  <StaticImage
                    placeholder="transparent"
                    src="../../images/benefits-chart1.svg"
                    alt="Ostara logo"
                    width={700}
                  />

                  <StaticImage
                    placeholder="transparent"
                    src="../../images/benefits-chart2.svg"
                    alt="Ostara logo"
                    width={700}
                  />
                </Carousel>
              </Box>
            </SimpleGrid>
          </Container>
        </Box> */}

        {/* <Box p={4} w="100%" className="soil-tie-up-body-container">
          <Container maxW={1400}>
            <hr className="custom-divider" />
          </Container>
          <Container
            className="soil-tie-up-body-3--container"
            maxW="container.md"
          >
            <div className="soil-tie-up-body-3--heading">
              <h3>
                Study Results: Crystal Green Increases Yield
              </h3>
              <p>
                Compared to grower-standard practices, Crystal Green fertilizers are proven to increase yield and quality, across a wide-range of soil pH.
              </p>
            </div>
          </Container>
          <Container
            className="soil-tie-up-body-3--arrow-lines key-top-box"
            maxW="container.lg"
          />
          <Container maxW="container.lg" className="soil-tie-up-body-3--chart1">
            <StaticImage
              placeholder="transparent"
              src="../../images/image-placeholder.png"
              alt="Ostara icons"
            />
          </Container>
          <Container className="line-end" maxW="container.lg" />
        </Box> */}

        <Box w="100%" p={4} className="soil-tie-up-body-container">
          <Container
            className="soil-tie-up-body-content-3--container"
            maxW={1400}
          >
            <SimpleGrid
              marginTop={[16, 40]}
              marginBottom={[16, 0]}
              columns={{ md: 1, lg: 2 }}
              spacing={20}
              className="product-player-container"
            >
              <Box w="100%">
                <h1>Research Proven</h1>
                <p>
                  In this video, Dr. Fred Below, professor at the University of
                  Illinois, discusses his research showing how Crystal Green
                  uses phosphorus more efficiently to help increase yield.
                </p>
              </Box>
              <Box w="100%">
                <ReactPlayer className="module-video" {...config} />
              </Box>
            </SimpleGrid>
          </Container>
        </Box>

        <Box w="100%" p={4} className="improve-yield-hero-container2">
          <div className="overlay" />
          <Container maxW={1400}>
            <Stack
              paddingTop={[16, 150]}
              paddingBottom={[16, 150]}
              maxW="45rem"
              spacing={6}
            >
              <h2>Improve Yield with Crystal Green Fertilizers</h2>
              <p>
                Crystal Green’s Crop Driven Release™ fertilizers increase
                agriculture yield by providing crops with critical nutrients
                when they need them while also reducing nutrient runoff and soil
                tie-up for maximum efficiency.
              </p>
              <CustomButton maxW={180} to="/product/crystal-green">
                LEARN MORE
              </CustomButton>
            </Stack>
          </Container>
        </Box>
        {/* <Box w="100%" p={4} className="benefits-research-container">
          <Container maxW={1400}>
            <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={6}>
              <Flex />
              <Flex>
                <Stack className="benefits-research-detail" spacing={6}>
                  <h4>CASE STUDY</h4>
                  <Link to="/contact">
                    Meet Julian and see how he enhanced yield after using
                    Crystal Green.
                  </Link>
                </Stack>
              </Flex>
            </SimpleGrid>
          </Container>
        </Box> */}
      </main>
    </Layout>
  );
};

export default Benefits;
